import React from 'react'
import styles from './Loader.module.scss'
import Context from '../../Context'

const Loader = () => (
   <Context.Consumer>
      {context => (
         <div className={context.offline ? styles.wrapperOffline : styles.wrapper}>
            <div className={styles.circle}></div>
            <div className={styles.text}>
               Wczytywanie
               <span className={styles.dot}>.</span>
               <span className={styles.dot}>.</span>
               <span className={styles.dot}>.</span>
            </div>
         </div>
      )}
   </Context.Consumer>
)

export default Loader