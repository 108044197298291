import React, { useEffect } from 'react'
import ReactHtmlParser from 'react-html-parser'
import EventItem from '../../components/EventItem/EventItem'
import Loader from '../../components/Loader/Loader'
import Pagination from '../../components/Pagination/Pagination'
import { URL } from '../../Config'
import Context from '../../Context'
import View404 from '../View404/View404'
import styles from './EventsCalendarView.module.scss'

const EventsCalendarView = ({ ...props }) => {
   const page = parseInt(props.match.params.page)
   const which = props.match.params.which
   const category = props.match.params.category

   useEffect(() => {
      document.title = "Kalendarz imprez"
   })

   return (
      <Context.Consumer>
         {context => (
            <>
               {context.eventsData ? (
                  <>
                     {which ? (
                        <>
                           {context.pagination(context.getFilteredEvents(which, category), page, 4, pagination => (
                              <>
                                 {pagination.data.length > 0 ? (
                                    <>
                                       <div className={styles.categoryTitle}>
                                          <h3>
                                             {context.getEventCategoryTitle(which, category)}
                                          </h3>
                                       </div>

                                       {pagination.data.map(event => (
                                          <EventItem
                                             key={event._id}
                                             title={event.title}
                                             date={event.date.format}
                                             description={ReactHtmlParser(event.description)}
                                             src={URL + event.headerimage.path}
                                             place={event.place}
                                             href={`/kalendarz-imprez/${which}/${category}/${page}/${event._id}`}
                                          />
                                       ))}
                                    </>
                                 ) : (
                                       <View404 />
                                    )}

                                 {page <= pagination.pages.length && page > 0 && pagination.pages.length > 1 && (
                                    <Pagination
                                       pagination={pagination}
                                       currentPage={page}
                                       baseLink={`/kalendarz-imprez/${which}/${category}`}
                                    />
                                 )}
                              </>
                           ))}
                        </>
                     ) : (
                           <>
                              {context.pagination(context.eventsData.entries, page, 4, pagination => (
                                 <>
                                    {pagination.data.length > 0 ? pagination.data.map(event => (
                                       <EventItem
                                          key={event._id}
                                          title={event.title}
                                          date={event.date.format}
                                          description={ReactHtmlParser(event.description)}
                                          src={URL + event.headerimage.path}
                                          place={event.place}
                                          href={`/kalendarz-imprez/${page}/${event._id}`}
                                       />
                                    )) : (
                                          <View404 />
                                       )}

                                    {page <= pagination.pages.length && page > 0 && pagination.pages.length > 1 && (
                                       <Pagination
                                          pagination={pagination}
                                          currentPage={page}
                                          baseLink="/kalendarz-imprez"
                                       />
                                    )}
                                 </>
                              ))}
                           </>
                        )}
                  </>
               ) : (
                     <Loader />
                  )}
            </>
         )}
      </Context.Consumer>
   )
}

export default EventsCalendarView