import React, { Suspense } from 'react'
import ReactHtmlParser from 'react-html-parser'
import BackButton from '../../components/BackButton/BackButton'
import FarmInfo from '../../components/FarmInfo/FarmInfo'
import Gallery from '../../components/Gallery/Gallery'
import InheritLoader from '../../components/InheritLoader/InheritLoader'
import Loader from '../../components/Loader/Loader'
import ServiceItem from '../../components/ServiceItem/ServiceItem'
import YTvideo from '../../components/YTvideo/YTvideo'
import { URL } from '../../Config'
import Context from '../../Context'
import View404 from '../View404/View404'
import styles from './FarmView.module.scss'

const SmallMap = React.lazy(() => import('../../components/SmallMap/SmallMap'))

const FarmView = ({ ...props }) => {
   const id = props.match.params.id || null

   return (
      <Context.Consumer>
         {context => (
            <>
               {id && context.farmsData ? context.getFarm(id, data => {
                  return (
                     <>
                        {data ? (
                           <div className={styles.wrapper}>
                              <div className={styles.content}>
                                 <BackButton href="/oferta-agroturystyczna" />
                                 <div className={styles.padding}>
                                    <h1>{data.name}</h1>
                                    <div className={styles.description}>
                                       {ReactHtmlParser(data.description)}
                                    </div>
                                    {data.yt && data.yt !== "" && (
                                       <YTvideo href={data.yt} />
                                    )}
                                 </div>
                                 <div className={styles.galleryContainer}>
                                    <Gallery images={data.photos} />
                                 </div>
                              </div>

                              <div className={styles.sidebar}>
                                 <Suspense fallback={<InheritLoader />}>
                                    <SmallMap localization={data.localization} />
                                 </Suspense>

                                 <div className={styles.sidebarDesc}>
                                    Województwo {data.address.province}
                                 </div>

                                 <h5>Informacje</h5>
                                 <FarmInfo icon="place" label="Adres" text={data.addressText} />
                                 <FarmInfo icon="represent" label="Właściciel" text={data.representative} />
                                 <FarmInfo icon="mail" label="E-mail" email={data.email} />
                                 <FarmInfo icon="call" label="Telefon" phone={data.phone} />
                                 {data.website && <FarmInfo icon="web" label="Strona www" href={data.website} />}
                                 {data.facebook && <FarmInfo icon="web" label="Facebook" facebook={data.facebook} />}

                                 <h5>Usługi</h5>
                                 {context.servicesData && data.services.map(element => {
                                    const service = context.getService(element._id)
                                    return (
                                       <ServiceItem
                                          key={`serviceItem-${element._id}`}
                                          name={service.name}
                                          icon={URL + service.icon.path}
                                       />
                                    )
                                 }
                                 )}

                                 {data.additionalatractions && data.additionalatractions[0].value && (
                                    <>
                                       <h5>Dodatkowe atrakcje</h5>
                                       <ul className={styles.list}>
                                          {data.additionalatractions.map((element, i) => (
                                             <li key={'listItem' + i}>{element.value}</li>
                                          ))}
                                       </ul>
                                    </>
                                 )}
                              </div>
                           </div>
                        ) : (
                              <View404 />
                           )}
                     </>
                  )
               }) : (
                     <Loader />
                  )}
            </>
         )}
      </Context.Consumer>
   )
}

export default FarmView