import React from 'react'
import styles from './FarmItem.module.scss'
import { Link } from 'react-router-dom'
import ServicesLineList from '../ServicesLineList/ServicesLineList'
import Img from '../Img/Img'

const FarmItem = ({...props}) => (
   <Link to={props.href} className={styles.wrapper}>
      <div className={styles.imageContainer}>
         <Img className={styles.image} src={props.src} size={[64, 64]} alt={props.title}/>
      </div>
      <div className={styles.content}>
         <div className={styles.title}>{props.title}</div>
         <div className={styles.place}>{props.place}</div>
         <ServicesLineList services={props.services} />
      </div>
   </Link>
)

export default FarmItem