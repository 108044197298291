import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.register()

window.author = `
  ┌────────────────┐
┌─┤ Development by ├───────────────┐
│ └────────────────┘               │
│   <···>                          │
│   Marcin Kalinowski              │
│   https://marcin-kalinowski.pl   │
│                                  │
└──────────────────────────────────┘
`