import React from 'react'
import styles from './YTvideo.module.scss'

const YTvideo = ({ href }) => {
   let hrefFilter = null
   if (href.match(/https:\/\/www.youtube.com\/watch\?/)) {
      hrefFilter = href.replace("https://www.youtube.com/watch?v=", "").split("&")[0]
   }
   if (href.match(/https:\/\/youtu.be\//)) {
      hrefFilter = href.replace("https://youtu.be/", "")
      console.log(hrefFilter)
   }

   if (hrefFilter) {
      const src = "https://www.youtube.com/embed/" + hrefFilter
      return (
         <div className={styles.wrapper}>
            <iframe className={styles.iframe} title="youtube" src={src} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
         </div>
      )
   } else {
      return null
   }
}

export default YTvideo