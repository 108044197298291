import React from 'react'
import styles from './FilterViewer.module.scss'
import Context from '../../../Context'

import { Room as IconPlace, FilterList as IconFilter } from '@material-ui/icons'

const FilterViewer = () => (
   <Context.Consumer>
      {context => (
         <div className={styles.wrapper}>
            {context.filter.provinces.map((filter, i) => (
               <div
                  key={`fprovince-${i}`}
                  className={styles.filter}
                  filter="provinces"
                  name={filter}
                  onClick={context.setFilter}
                  rerender="true"
               >
                  <IconPlace className={styles.icon}/>
                  {filter}
               </div>
            ))}
            {context.filter.services.map((filter, i) => (
               <div
                  key={`fservice-${i}`}
                  className={styles.filter}
                  filter="services"
                  name={filter}
                  onClick={context.setFilter}
                  rerender="true"
               >
                  <IconFilter className={styles.icon}/>
                  {context.getService(filter).name}
               </div>
            ))}
         </div>
      )}
   </Context.Consumer>
)

export default FilterViewer