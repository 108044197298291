import React from 'react'
import styles from './BackButton.module.scss'
import { Link } from 'react-router-dom'
import IconArrowBack from '@material-ui/icons/ArrowBack'

const BackButton = ({...props}) => (
   <>
      {props.backAction ? (
            <button
            className={styles.button}
            onClick={props.backAction}
         >
            <IconArrowBack className={styles.icon}/>
         </button>
      ) : null}
      {props.href ? (
         <Link
            className={styles.button}
            to={props.href}
         >
            <IconArrowBack className={styles.icon}/>
         </Link>
      ) : null}
   </>
)

export default BackButton