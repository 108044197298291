import React from 'react'
import styles from './BoxButton.module.scss'
import { Link } from 'react-router-dom'

const BoxButton = ({...props}) => {
   const Icon = props.icon
   return (
      <div className={props.theme === 'light' ? styles.wrapperLight : styles.wrapperDark}>
         <Link to={props.href} className={styles.link}>
            <Icon className={styles.icon}/>
            <div className={styles.title}>
               {props.title}
            </div>
         </Link>
      </div>
   )
}

export default BoxButton