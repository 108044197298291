import React from 'react'
import styles from './TitleBar.module.scss'

const TitleBar = ({ children }) => (
   <div className={styles.wrapper}>
      <div className={styles.title}>
         {children}
      </div>
      <div className={styles.line}></div>
   </div>
)

export default TitleBar