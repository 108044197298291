import React from 'react'
import styles from './MobileBar.module.scss'
import Context from '../../Context'
import SidebarIcon from './SidebarIcon/SidebarIcon'
import { Route, Link } from 'react-router-dom'
import Image from '../../data/Images'
import Img from '../Img/Img'

const MobileBar = () => (
   <Context.Consumer>
      {context => (
         <>
            <div className={context.offline ? styles.wrapperOffline : styles.wrapper}>
               <div
                  className={
                     context.isMenuOpen ?
                     styles.menuIconActive :
                     styles.menuIcon
                  }
                  onClick={context.openCloseMenu}
               >
                  <div className={styles.line}></div>
                  <div className={styles.line}></div>
                  <div className={styles.line}></div>
               </div>
               <Link to="/">
                  <Img className={styles.logo} src={Image.LogoSmall} alt="Logo"/>
               </Link>

               <Route path="/kalendarz-imprez">
                  <SidebarIcon icon="more"/>
               </Route>
               <Route path="/oferta-agroturystyczna">
                  <SidebarIcon icon="search"/>
               </Route>
            </div>
            <div className={
                  context.isMenuOpen || context.isSidebarOpen ?
                  styles.shadowActive :
                  styles.shadow
               }
               onClick={context.closeAllMenus}
            ></div>
         </>
      )}
   </Context.Consumer>
)

export default MobileBar