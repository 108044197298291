import React from 'react'
import { Sort as IconSort, FilterList as IconFilter } from '@material-ui/icons'
import styles from './SortFilter.module.scss'

const SortFilter = ({...props}) => (
   <div className={styles.wrapper}>
      <button
         type="button"
         className={styles.button}
         onClick={props.changeToSort}
      >
         Sortuj
         <IconSort className={styles.icon}/>
      </button>
      <button
         type="button"
         className={styles.button}
         onClick={props.changeToFilter}
      >
         Filtruj
         <IconFilter className={styles.icon}/>
      </button>
   </div>
)

export default SortFilter
