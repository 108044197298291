import { sampleSize } from 'lodash'
import React from 'react'
import API from './API_Connect'
import Context from './Context'
import * as Utils from './Utils'
import Root from './views/Root/Root'

class App extends React.Component {
   state = {
      isMenuOpen: false,
      isSidebarOpen: false,
      farmsData: null,
      randomFarms: null,
      discoverFarms: null,
      eventsData: null,
      pagesData: null,
      searchPhrase: '',
      searchData: null,
      servicesData: null,
      eventsDates: null,
      adsData: null,
      showFilters: true,
      sort: {
         howto: 'desc',
         name: 'az'
      },
      filter: {
         services: [],
         provinces: []
      },
      offline: false
   }

   componentDidMount() {
      API.getCollection('Farms', res => {
         res.entries = Utils.convertFarmsAddresses(res.entries)
         this.setState({
            farmsData: res,
            randomFarms: sampleSize(res.entries, 4)
         })
      }, Utils.setOffline.bind(this))

      API.getCollection('Events', res => {
         Utils.convertDate(res.entries)

         res.entries = res.entries.sort((a, b) => {
            if (a.date.timestamp > b.date.timestamp) return -1
            if (a.date.timestamp < b.date.timestamp) return 1
            return 0
         })

         const eventsDates = Utils.getEventsDates(res.entries)

         this.setState({
            eventsData: res,
            eventsDates: eventsDates
         })
      }, Utils.setOffline.bind(this))

      API.getCollection('Pages', res => {
         res.entries = res.entries.sort((a, b) => {
            if (a.order > b.order) return 1
            if (a.order < b.order) return -1
            return 0
         })

         this.setState({
            pagesData: res
         })
      }, Utils.setOffline.bind(this))

      API.getCollection('Services', res => {
         this.setState({
            servicesData: res
         })
      }, Utils.setOffline.bind(this))

      API.getCollection('ads', res => {
         this.setState({
            adsData: Utils.filterAds(res)
         })
      }, Utils.setOffline.bind(this))
   }

   render() {
      const context = {
         ...this.state,
         openCloseMenu: Utils.openCloseMenu.bind(this),
         openCloseSidebar: Utils.openCloseSidebar.bind(this),
         closeAllMenus: Utils.closeAllMenus.bind(this),
         getFarm: Utils.getFarm.bind(this),
         getEvent: Utils.getEvent.bind(this),
         getPage: Utils.getPage.bind(this),
         pagination: Utils.pagination.bind(this),
         setSearchPhrase: Utils.setSearchPhrase.bind(this),
         setSort: Utils.setSort.bind(this),
         setFilter: Utils.setFilter.bind(this),
         getService: Utils.getService.bind(this),
         getFilteredEvents: Utils.getFilteredEvents.bind(this),
         getEventCategoryTitle: Utils.getEventCategoryTitle.bind(this),
         setFilterProvince: Utils.setFilterProvince.bind(this)
      }

      return (
         <Context.Provider value={context}>
            <Root />
         </Context.Provider>
      )
   }
}

export default App