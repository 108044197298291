import React from 'react'
import {
   ArrowBack as IconArrowBack,
   ArrowForward as IconArrowForward
} from '@material-ui/icons'
import styles from './Pagination.module.scss'
import { Link } from 'react-router-dom'

const Pagination = ({...props}) => (
   <div className={styles.wrapper}>
      <div className={styles.pagination}>
         {props.pagination.prev ? (
            <Link className={styles.link} to={props.baseLink + '/' + props.pagination.prev}>
               <IconArrowBack className={styles.icon}/>
            </Link>
         ) : (
            <div className={styles.linkDisable}>
               <IconArrowBack className={styles.icon}/>
            </div>
         )}

         {props.pagination.pages.map(page => {
            return page === parseInt(props.currentPage) ? (
               <div key={'pagination' + page} className={styles.linkActive}>
                  {page}
               </div>
            ) : (
               <Link key={'pagination' + page} className={styles.link} to={props.baseLink + '/' + page}>
                  {page}
               </Link>
            )
         })}

         {props.pagination.next ? (
            <Link className={styles.link} to={props.baseLink + '/' + props.pagination.next}>
               <IconArrowForward className={styles.icon}/>
            </Link>
         ) : (
            <div className={styles.linkDisable}>
               <IconArrowForward className={styles.icon}/>
            </div>
         )}
      </div>
   </div>
)

export default Pagination