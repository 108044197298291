import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import Container from '../../components/Container/Container'
import Loader from '../../components/Loader/Loader'
import { URL } from '../../Config'
import Context from '../../Context'
import View404 from '../View404/View404'

const InfoPageView = ({ ...props }) => {
   const slug = props.match.params.slug

   return (
      <Context.Consumer>
         {context => (
            <>
               {context.pagesData ? context.getPage(slug, data => {
                  return (
                     <>
                        {data ? (
                           <Container image={data.image.path ? URL + data.image.path : null}>
                              <h1>{data.title}</h1>
                              {ReactHtmlParser(data.content.replace(/src="\/cockpit/gi, `src="${URL}/cockpit`))}
                           </Container>
                        ) : (
                              <View404 />
                           )}
                     </>
                  )
               }) : (
                     <Loader />
                  )}
            </>
         )}
      </Context.Consumer>
   )
}

export default InfoPageView