import React from 'react'
import styles from './NavItem.module.scss'
import { NavLink } from 'react-router-dom'

const NavItem = ({ children, ...props }) => (
   <li className={styles.wrapper}>
      <NavLink
         exact={props.exact}
         activeClassName={styles.linkActive}
         className={styles.link}
         to={props.href}>
         {children}
      </NavLink>
   </li>
)

export default NavItem