import React from 'react'
import styles from './Checkbox.module.scss'
import Context from '../../../Context'

const Checkbox = ({children, ...props}) => (
   <Context.Consumer>
      {context => (
         <div className={styles.wrapper}>
            <input
               type="checkbox"
               id={props.id}
               name={props.name}
               className={styles.checkbox}
               defaultChecked={props.checked}
               filter={props.filter}
               onChange={context.setFilter}
            />
            <div className={styles.checkmark}></div>
            <label htmlFor={props.id} className={styles.label}>
               {children}
            </label>
         </div>
      )}
   </Context.Consumer>
)

export default Checkbox