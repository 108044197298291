import React from 'react'
import styles from './Image.module.scss'
import Img from '../../Img/Img'

const Image = ({...props}) => (
   <div
      className={styles.wrapper}
      onClick={props.onClick}
   >
      <Img
         src={props.src}
         alt={props.title}
         className={styles.image}
         size={[300, 300]}
      />
   </div>
)

export default Image