import React from 'react'
import { withRouter } from 'react-router-dom'

class Reset extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      window.scrollTo({top: 0, behavior: 'smooth'})
      this.props.closeAllMenus()
    }
  }

  render() {
    return null
  }
}

export default withRouter(Reset)