import React from 'react'
import styles from './ServiceItem.module.scss'
import IconCheckCircle from '@material-ui/icons/CheckCircle'

class ServiceItem extends React.Component {
   state = {
      isError: false
   }

   handleError() {
      this.setState({
         isError: true
      })
   }

   render() {
      return (
         <div className={styles.wrapper}>
            {!this.state.isError ? (
               <img
                  src={this.props.icon}
                  alt={this.props.name}
                  className={styles.icon}
                  onError={this.handleError.bind(this)}
               />
            ) : (
               <IconCheckCircle className={styles.replacementIcon}/>
            )}
            <div className={styles.label}>
               {this.props.name}
            </div>
         </div>
      )
   }
}

export default ServiceItem