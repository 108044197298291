import React from 'react'
import IconSearch from '@material-ui/icons/Search'
import styles from './SearchBarPanel.module.scss'
import Context from '../../../Context'

class SearchBarPanel extends React.Component {
   componentDidMount() {
      this.props.updateSearchPhrase(this.props.defaultValue)
   }
   render() {
      return (
         <Context.Consumer>
            {context => (
               <div className={styles.wrapper}>
                  <input
                     onChange={context.setSearchPhrase}
                     className={styles.input}
                     type="search"
                     placeholder="Szukaj gospodarstw..."
                     defaultValue={this.props.defaultValue}
                  />
                  <div className={styles.searchIcon}>
                     <IconSearch className={styles.icon}/>
                  </div>
               </div>
            )}
         </Context.Consumer>
      )
   }
}

export default SearchBarPanel