import IconSearch from '@material-ui/icons/Search'
import React from 'react'
import { Redirect } from 'react-router-dom'
import styles from './SearchBar.module.scss'

class SearchBar extends React.Component {
   state = {
      phrase: null
   }

   search(e) {
      e.persist()
      const phrase = e.target.elements.search.value
      if (phrase !== '') {
         this.setState({
            phrase: phrase
         })
      }
      e.preventDefault()
   }

   render() {
      return (
         <>
            <form className={styles.wrapper} onSubmit={this.search.bind(this)}>
               <input
                  className={styles.input}
                  type="search"
                  placeholder="Szukaj gospodarstw..."
                  name="search"
                  aria-label="Wpisz szukaną frazę"
               />
               <button className={styles.submit} type="submit" aria-label="Kliknij aby wyszukać">
                  <IconSearch className={styles.icon} />
               </button>
            </form>
            {this.state.phrase !== null && (
               <Redirect to={`/oferta-agroturystyczna?search=${this.state.phrase}`} />
            )}
         </>
      )
   }
}

export default SearchBar