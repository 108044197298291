import React from 'react'
import styles from './Footer.module.scss'
import FooterLink from './FooterLink/FooterLink'
import Context from '../../Context'

const date = new Date()
const year = date.getFullYear()

const Footer = () => (
   <Context.Consumer>
      {context => (
         <footer className={styles.wrapper}>
            <div className={styles.description}>
               Mazowiecko Podlaskie Stowarzyszenie Agroturystyczne &copy; {year} | Projekt i wykonanie: <a href="http://intermedial.pl/" rel="noopener noreferrer" target="_blank" >Intermedial</a>
            </div>
            <ul className={styles.links}>
               {context.pagesData && context.pagesData.entries.filter(x => x.navigation === 'footer').map(item => (
                  <FooterLink key={`footerItem-${item._id}`} href={`/${item.title_slug}`}>{item.title}</FooterLink>
               ))}
            </ul>
         </footer>
      )}
   </Context.Consumer>
)

export default Footer
