import React from 'react'
import styles from './EventItem.module.scss'
import { Link } from 'react-router-dom'
import Img from '../Img/Img'

const EventItem = ({...props}) => (
   <Link to={props.href} className={styles.wrapper}>
      <div className={styles.thumbnail}>
         <Img src={props.src} size={[200, 200]} alt="test" className={styles.image}/>
      </div>
      <div className={styles.content}>
         <div className={styles.infoBar}>
            <div className={styles.date}>
               {props.date}
            </div>
            <div className={styles.dot}></div>
            <div className={styles.place}>
               {props.place}
            </div>
         </div>
         <div className={styles.title}>
            {props.title}
         </div>
         <div className={styles.description}>
            {props.description}
         </div>
         <div className={styles.readMore}>
            Czytaj więcej...
         </div>
      </div>
   </Link>
)

export default EventItem