import React from 'react'
import { Link } from 'react-router-dom'
import { URL } from '../../../Config'
import Context from '../../../Context'
import Img from '../../Img/Img'
import styles from './NavBanner.module.scss'

const NavBanner = () => (
   <Context.Consumer>
      {(context) => (
         <>
            {context.adsData && (
               <>
                  <Link
                     to={`/oferta-agroturystyczna/${context.adsData.farm._id}`}
                     className={styles.wrapper}
                  >
                     <Img
                        src={URL + context.adsData.image.path}
                        size={[200, 200]}
                        alt='NavBanner'
                        className={styles.image}
                     />
                     <div className={styles.content}>
                        {context.adsData.title !== '' && (
                           <div className={styles.title}>
                              {context.adsData.title}
                           </div>
                        )}
                        {context.adsData.title !== '' &&
                           context.adsData !== '' && (
                              <div className={styles.separator}></div>
                           )}
                        {context.adsData.description !== '' && (
                           <div className={styles.description}>
                              {context.adsData.description}
                           </div>
                        )}
                        <div className={styles.button}>Sprawdź</div>
                     </div>
                     <div className={styles.background}></div>
                  </Link>
                  <div className={styles.bannerTitle}>Reklama</div>
               </>
            )}
         </>
      )}
   </Context.Consumer>
)

export default NavBanner
