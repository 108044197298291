import React from 'react'
import styles from './OfflineNotice.module.scss'
import Context from '../../Context'

const OfflineNotice = () => (
   <Context.Consumer>
      {context => (
         <>
            {context.offline && (
               <div className={styles.wrapper}>
                  Strona działa obecnie w trybie offline
               </div>
            )}
         </>
      )}
   </Context.Consumer>
)

export default OfflineNotice