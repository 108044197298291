import React from 'react'
import styles from './SideBarLink.module.scss'
import { Link } from 'react-router-dom'

const SideBarLink = ({ children, ...props }) => (
   <Link to={props.link} className={styles.wrapper}>
      {children}
   </Link>
)

export default SideBarLink
