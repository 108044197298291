import React from 'react'
import styles from './EventsSidebar.module.scss'
import MenuList from '../MenuList/MenuList'
import TitleBar from '../TitleBar/TitleBar'
import SideBarLink from './SidebarLink/SidebarLink'

import SmoothScrollbar from 'smooth-scrollbar'
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll'
import Scrollbar from 'react-smooth-scrollbar'
import Context from  '../../Context'

SmoothScrollbar.use(OverscrollPlugin)

const EventsSidebar = () => (
   <Context.Consumer>
      {context => (
         <MenuList>
            <Scrollbar className={styles.scrollPanel}>
               <div className={styles.container}>
                  {context.eventsDates && (
                     <>
                        {context.eventsDates.future.length > 0 && (
                           <>
                              <TitleBar>Nadchodzące imprezy</TitleBar>
                              {context.eventsDates.future.map((event, i) => (
                                 <SideBarLink key={`future-link-${i}`} link={event.link}>{event.display}</SideBarLink>
                              ))}
                           </>
                        )}

                        {context.eventsDates.past.length > 0 && (
                           <>
                              <TitleBar>Archiwum imprez</TitleBar>
                              {context.eventsDates.past.map((event, i) => {
                                 if(i < 10) {
                                    return (
                                       <SideBarLink key={`past-link-${i}`} link={event.link}>{event.display}</SideBarLink>
                                    )
                                 } else {
                                    return null
                                 }
                              })}
                           </>
                        )}
                     </>
                  )}
               </div>
            </Scrollbar>
         </MenuList>
      )}
   </Context.Consumer>
)

export default EventsSidebar