import IconPublic from '@material-ui/icons/Public'
import React, { useEffect } from 'react'
import BoxButton from '../../components/BoxButton/BoxButton'
import FarmItem from '../../components/FarmItem/FarmItem'
import { URL } from '../../Config'
import Context from '../../Context'
import styles from './FarmsIndexView.module.scss'

const FarmsIndexView = () => {
   const letters = []

   useEffect(() => {
      document.title = "Indeks gospodarstw"
   })

   return (
      <Context.Consumer>
         {context => (
            <>
               <div className={styles.wrapper}>
                  <h1>Indeks gospodarstw</h1>

                  {context.farmsData && context.farmsData.entries.sort((a, b) => {
                     if (a.name > b.name) return 1
                     if (a.name < b.name) return -1
                     return 0
                  }).map(element => {
                     const firstLetter = element.name.charAt(0)
                     let newLetter = false
                     if (letters.indexOf(firstLetter) === -1) {
                        letters.push(firstLetter)
                        newLetter = true
                     }

                     return (
                        <React.Fragment key={'index-' + element._id}>
                           {newLetter && (
                              <div className={styles.letter}>
                                 {firstLetter}
                              </div>
                           )}
                           <FarmItem
                              title={element.name}
                              src={URL + element.photos[0].path}
                              services={element.services}
                              href={`/oferta-agroturystyczna/${element._id}`}
                              place={element.addressText}
                           />
                        </React.Fragment>
                     )
                  })}
               </div>
               <BoxButton
                  icon={IconPublic}
                  title="Zobacz na mapie"
                  href="/oferta-agroturystyczna"
                  theme="dark"
               />
            </>
         )}
      </Context.Consumer>
   )
}

export default FarmsIndexView

