import React from 'react'
import styles from './HeaderPhrase.module.scss'
import ReactHtmlParser from 'react-html-parser'
import { random } from 'lodash'

const phrases = [
   "Zaplanuj swój urlop na wsi",
   "Na wsi bez stresu, bez pośpiechu",
   "Wypocznij na wsi",
   "Znajdź swój wymarzony odpoczynek",
   "Na wsi milej płynie czas",
   "Odetchnij świeżym powietrzem na wsi"
]


class HeaderPhrase extends React.Component {
   state = {
      letters: null
   }

   componentDidMount() {
      const phrase = phrases[random(0, phrases.length - 1)]
      const letters = phrase.split('')
      this.setState({
         letters: letters
      })
   }

   render() {

      return (
         <>
            {this.state.letters && (
               <div className={styles.wrapper}>
                  <div className={styles.content}>
                     {this.state.letters.map((letter, i) => {
                        if(letter === ' ') {
                           letter = ReactHtmlParser('&nbsp;')
                        }
                        const style = {
                           animationDelay: (0.5 + i * 0.04) + 's'
                        }
                        return (
                           <span key={`letter-${i}`} className={styles.letter} style={style}>
                              {letter}
                           </span>
                        )
                     })}
                  </div>
                  <div className={styles.background}></div>
               </div>
            )}
         </>
      )
   }
}

export default HeaderPhrase