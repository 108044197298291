import logo from '../assets/images/logo.svg'
import navBanner from '../assets/images/NavBanner.png'
import banner from '../assets/images/banner.jpg'
import homeBanner from '../assets/images/homeBanner.jpg'
import smallmap from '../assets/images/smallmap.png'
import logosmall from '../assets/images/logosmall.png'
import pointIcon from '../assets/images/pointIcon.svg'
import cityIcon from '../assets/images/cityIcon.svg'
import labelIcon from '../assets/images/labelIcon.svg'
import offline from '../assets/images/offline.svg'

const Images = {
   "Logo": logo,
   "NavBanner": navBanner,
   "Banner": banner,
   "HomeBanner": homeBanner,
   "SmallMap": smallmap,
   "LogoSmall": logosmall,
   "PointIcon": pointIcon,
   "CityIcon": cityIcon,
   "LabelIcon": labelIcon,
   "Offline": offline
}

export default Images