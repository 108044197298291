import React from 'react'
import { Sort as IconSort, FilterList as IconFilter } from '@material-ui/icons'
import styles from './SearchPanel.module.scss'
import SortFilter from './SortFilter/SortFilter'
import SearchBarPanel from './SearchBarPanel/SearchBarPanel'
import TitleBar from '../TitleBar/TitleBar'
import FarmItem from '../FarmItem/FarmItem'
// import PlaceItem from './PlaceItem/PlaceItem'
import SmoothScrollbar from 'smooth-scrollbar'
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll'
import Scrollbar from 'react-smooth-scrollbar'
import BackButton from '../BackButton/BackButton'
import SortFilterHeader from './SortFilterHeader/SortFilterHeader'
import Checkbox from './Checkbox/Checkbox'
import Radio from './Radio/Radio'
import Context from '../../Context'
import { URL } from '../../Config'
import FilterViewer from './FilterViewer/FilterViewer'


SmoothScrollbar.use(OverscrollPlugin)

const MODE = {
   "search": 1,
   "filter": 2,
   "sort": 3
}

class SearchPanel extends React.Component {
   state = {
      mode: MODE.search,
      searchPhrase: null,
      canShowSearch: false
   }

   componentDidMount() {
      const urlParams = new URLSearchParams(window.location.search)
      const search = urlParams.get('search')
      this.setState({
         searchPhrase: search
      }, () => {
         this.setState({
            canShowSearch: true
         })
      })
   }

   changeToSort() {
      this.setState({
         mode: MODE.sort
      })
   }

   changeToFilter() {
      this.setState({
         mode: MODE.filter
      })
   }

   changeToSearch() {
      this.setState({
         mode: MODE.search
      })
   }

   render() {
      return (
         <Context.Consumer>
            {context => (
               <div className={context.offline ? styles.wrapperOffline : styles.wrapper}>
                  <div className={this.state.mode === MODE.search ? styles.searchContainerActive : styles.searchContainer}>
                        <SortFilter
                           changeToSort={this.changeToSort.bind(this)}
                           changeToFilter={this.changeToFilter.bind(this)}
                        />

                     {this.state.canShowSearch && context.farmsData && (
                        <SearchBarPanel
                           defaultValue={this.state.searchPhrase !== null ? this.state.searchPhrase : ''}
                           updateSearchPhrase={context.setSearchPhrase}
                        />
                     )}
                     <Scrollbar className={context.offline ? styles.scrollPanelSearchOffline : styles.scrollPanelSearch}>
                        <FilterViewer/>
                        {context.searchData !== null ? (
                           <>
                              <TitleBar>Wyszukane gospodarstwa</TitleBar>
                              {context.searchData.farms.length !== 0 ? context.searchData.farms.map(element => (
                                 <FarmItem
                                    key={element._id}
                                    title={element.name}
                                    src={URL + element.photos[0].path}
                                    services={element.services}
                                    href={`/oferta-agroturystyczna/${element._id}`}
                                    place={element.addressText}
                                 />
                              )) : (
                                 <div className={styles.norecords}>Brak wyników</div>
                              )}
                           </>
                        ) : (
                           <>
                              <TitleBar>Odkrywaj</TitleBar>
                              {context.discoverFarms && context.discoverFarms.map(element => (
                                 <FarmItem
                                    key={element._id}
                                    title={element.name}
                                    src={URL + element.photos[0].path}
                                    services={element.services}
                                    href={`/oferta-agroturystyczna/${element._id}`}
                                    place={element.addressText}
                                 />
                              ))}
                              <div className={styles.space}></div>
                           </>
                        )}
                     </Scrollbar>
                  </div>
                  <div className={this.state.mode === MODE.filter ? styles.filterContainerActive : styles.filterContainer}>
                     <BackButton backAction={this.changeToSearch.bind(this)}/>
                     <SortFilterHeader>
                        Filtruj
                        <IconFilter className={styles.icon}/>
                     </SortFilterHeader>

                     <Scrollbar className={context.offline ? styles.scrollPanelOffline : styles.scrollPanel}>
                        {context.showFilters && (
                           <>
                              <TitleBar>Według usługi</TitleBar>

                              {context.servicesData && context.servicesData.entries.map((service, index) => (
                                 <Checkbox
                                    key={`service-${index}`}
                                    id={`service-${index}`}
                                    filter="services"
                                    name={service._id}
                                    checked={context.filter.services.indexOf(service._id) !== -1}
                                 >
                                    {service.name}
                                 </Checkbox>
                              ))}

                              <TitleBar>Według województwa</TitleBar>
                              <Checkbox
                                 id="province-1"
                                 filter="provinces"
                                 name="Lubelskie"
                                 checked={context.filter.provinces.indexOf('Lubelskie') !== -1}
                              >
                                 Lubelskie
                              </Checkbox>
                              <Checkbox
                                 id="province-2"
                                 filter="provinces"
                                 name="Mazowieckie"
                                 checked={context.filter.provinces.indexOf('Mazowieckie') !== -1}
                              >
                                 Mazowieckie
                              </Checkbox>
                              <Checkbox
                                 id="province-3"
                                 filter="provinces"
                                 name="Podlaskie"
                                 checked={context.filter.provinces.indexOf('Podlaskie') !== -1}
                              >
                                 Podlaskie
                              </Checkbox>
                              <div className={styles.space}></div>
                           </>
                        )}

                     </Scrollbar>
                  </div>
                  <div className={this.state.mode === MODE.sort ? styles.sortContainerActive : styles.sortContainer}>
                     <BackButton backAction={this.changeToSearch.bind(this)}/>

                     <SortFilterHeader>
                        Sortuj
                        <IconSort className={styles.icon}/>
                     </SortFilterHeader>

                     <Scrollbar className={context.offline ? styles.scrollPanelOffline : styles.scrollPanel}>
                        <TitleBar>Jak?</TitleBar>
                        <Radio id="radio1" name="howto" value="asc" checked={context.sort.howto === 'asc'}>
                           Rosnąco
                        </Radio>
                        <Radio id="radio2" name="howto" value="desc" checked={context.sort.howto === 'desc'}>
                           Malejąco
                        </Radio>

                        <TitleBar>Po czym?</TitleBar>
                        <Radio id="radio3" name="name" value="az" checked={context.sort.name === 'az'}>
                           Alfabetycznie
                        </Radio>
                        <Radio id="radio4" name="name" value="placename" checked={context.sort.name === 'placename'}>
                           Po adresie
                        </Radio>
                        <Radio id="radio5" name="name" value="servicescount" checked={context.sort.name === 'servicescount'}>
                           Po ilościu usług
                        </Radio>
                        <div className={styles.space}></div>
                     </Scrollbar>
                  </div>
               </div>
            )}
         </Context.Consumer>
      )
   }
}

export default SearchPanel