import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import BackButton from '../../components/BackButton/BackButton'
import Gallery from '../../components/Gallery/Gallery'
import Img from '../../components/Img/Img'
import Loader from '../../components/Loader/Loader'
import { URL } from '../../Config'
import Context from '../../Context'
import View404 from '../View404/View404'
import styles from './EventView.module.scss'

const EventView = ({ ...props }) => {
   const id = props.match.params.id || null
   const page = props.match.params.page
   const which = props.match.params.which
   const category = props.match.params.category
   return (
      <Context.Consumer>
         {context => (
            <>
               {id && context.eventsData ? context.getEvent(id, data => {
                  return (
                     <>
                        {data ? (
                           <div className={styles.wrapper}>
                              {data.headerimage && (
                                 <Img className={styles.headerImage} src={URL + data.headerimage.path} size={[1000, 400]} alt="test" />
                              )}
                              {which ? (
                                 <BackButton href={`/kalendarz-imprez/${which}/${category}/${page}`} />
                              ) : (
                                    <BackButton href={`/kalendarz-imprez/${page}`} />
                                 )}
                              <div className={styles.content}>
                                 <div className={styles.infoBar}>
                                    <div className={styles.date}>{data.date.format}</div>
                                    <div className={styles.dot}></div>
                                    <div className={styles.place}>{data.place}</div>
                                 </div>
                                 <h1>{data.title}</h1>
                                 {ReactHtmlParser(data.description)}
                              </div>
                              {data.photos && (
                                 <div className={styles.galleryContainer}>
                                    <Gallery images={data.photos} />
                                 </div>
                              )}
                           </div>
                        ) : (
                              <View404 />
                           )}
                     </>
                  )
               }) : (
                     <Loader />
                  )}
            </>
         )}
      </Context.Consumer>
   )
}

export default EventView