import React from 'react'
import styles from './Container.module.scss'
import Img from '../Img/Img'

const Container = ({ children, ...props }) => (
   <div className={styles.wrapper}>
      {props.image ? (
         <Img className={styles.image} src={props.image} size={[800, 250]} alt={props.title ? props.title : ''}/>
      ) : null}
      <div className={styles.content}>
         {children}
      </div>
   </div>
)

export default Container