import React, { useEffect } from 'react'
import styles from './View404.module.scss'
import Container from '../../components/Container/Container'
import { useLocation, Link } from 'react-router-dom'
import IconWarning from '@material-ui/icons/Warning'

const View404 = () => {
   useEffect(() => {
      document.title = "Błąd 404"
   })

   return (
      <Container>
         <div className={styles.iconContainer}>
            <IconWarning className={styles.icon}/>
         </div>
         <div className={styles.header}>404</div>
         <p className={styles.content}>
            Wygląda na to, że nie ma strony o podanym adresie:
            <span className={styles.address}>
               {useLocation().pathname}
            </span>
            lub została usunięta.
            <br/>
            <br/>
            <Link to="/">Wróć do strony głównej</Link>
         </p>
      </Container>
   )
}
export default View404