import React from 'react'
import styles from './Img.module.scss'
import API from '../../API_Connect'
import Images from '../../data/Images'
import ls from 'localstorage-ttl'

class Img extends React.Component {
   state = {
      isLoaded: false,
      isError: false,
      imageUrl: null,
      originalUrl: null
   }

   componentDidMount() {
      this.setState({
         originalUrl: this.props.src
      }, () => {
         this.getImageThumb()
      })
   }

   componentDidUpdate() {
      if (this.props.src !== this.state.originalUrl) {
         this.setState({
            imageUrl: null,
            originalUrl: this.props.src,
            isLoaded: false
         }, () => {
            this.getImageThumb()
         })
      }
   }


   getImageThumb() {
      if (this.props.size) {
         const apiUrl = API.getImageThumbUrl(this.props.src, ...this.props.size)
         const url = ls.get(apiUrl)

         if (url) {
            this.setState({
               imageUrl: url
            })
         } else {
            API.getImageThumb(apiUrl, res => {
               ls.set(apiUrl, res, 2592000)
               this.setState({
                  imageUrl: res
               })
            }, () => {
               this.setState({
                  imageUrl: Images.Offline
               })
            })
         }
      }
   }

   handleImageLoaded() {
      this.setState({
         isLoaded: true
      })
   }

   handleError() {
      this.setState({
         isError: true
      })
   }

   render() {
      return (
         <div className={this.props.className}>
            <div className={styles.wrapper}>
               {this.props.size ? (
                  <>
                     {this.state.imageUrl && (
                        <img
                           src={this.state.isError ? Images.Offline : this.state.imageUrl}
                           alt={this.props.alt || ''}
                           className={this.state.isLoaded ? styles.img : styles.imgHide}
                           onLoad={this.handleImageLoaded.bind(this)}
                           onError={this.handleError.bind(this)}
                        />
                     )}
                  </>
               ) : (
                     <img
                        src={this.state.isError ? Images.Offline : this.props.src}
                        alt={this.props.alt || ''}
                        className={this.state.isLoaded ? styles.img : styles.imgHide}
                        onLoad={this.handleImageLoaded.bind(this)}
                        onError={this.handleError.bind(this)}
                     />
                  )}
               <div className={this.state.isLoaded ? styles.loaderHide : styles.loader}></div>
            </div>
         </div>
      )
   }
}

export default Img