import React from 'react'
import styles from './Navigation.module.scss'
import MenuList from '../MenuList/MenuList'
import Images from '../../data/Images'
import Separator from '../Separator/Separator'
import NavItem from './NavItem/NavItem'
import NavBanner from './NavBanner/NavBanner'
import { Link } from 'react-router-dom'
import Img from '../Img/Img'
import Context from '../../Context'

import SmoothScrollbar from 'smooth-scrollbar'
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll'
import Scrollbar from 'react-smooth-scrollbar'


SmoothScrollbar.use(OverscrollPlugin)

const Navigation = () => (
   <Context.Consumer>
      {context => (
         <nav>
            <MenuList>
               <Scrollbar className={context.offline ? styles.scrollPanelOffline : styles.scrollPanel}>
                  <div className={styles.container}>
                     <Link to="/">
                        <Img src={Images.Logo} alt="Logo" className={styles.logo}/>
                     </Link>

                     <Separator/>

                     <ul className={styles.navList}>
                        <NavItem href="/" exact>Strona główna</NavItem>
                        <NavItem href="/oferta-agroturystyczna">Oferta agroturystyczna</NavItem>
                        <NavItem href="/kalendarz-imprez/">Kalendarz imprez</NavItem>
                        {context.pagesData && context.pagesData.entries.filter(x => x.navigation === 'main').map(item => (
                           <NavItem key={`navItem-${item._id}`} href={`/${item.title_slug}`}>{item.title}</NavItem>
                        ))}
                     </ul>

                     <NavBanner/>
                  </div>
               </Scrollbar>
            </MenuList>
         </nav>
      )}
   </Context.Consumer>
)

export default Navigation