import IconTop from '@material-ui/icons/ArrowUpward'
import React from 'react'
import { Debounce } from '../../Utils'
import styles from './ToTop.module.scss'

const debounce = new Debounce()

class ToTop extends React.Component {
   state = {
      isHide: true,
   }

   componentDidMount() {
      window.addEventListener('scroll', this.handleScroll.bind(this))
   }

   handleScroll() {
      debounce.run(() => {
         if (window.scrollY > 500) {
            this.setState({
               isHide: false,
            })
         } else {
            this.setState({
               isHide: true,
            })
         }
      }, 300)
   }

   handleClick() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
   }

   render() {
      return (
         <button
            type='button'
            className={this.state.isHide ? styles.wrapperHide : styles.wrapper}
            onClick={this.handleClick}
            aria-label="Przewiń w górę"
         >
            <IconTop className={styles.icon} />
         </button>
      )
   }
}

export default ToTop
