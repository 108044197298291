import React from 'react'
import styles from './MenuList.module.scss'
import Context from '../../Context'

const MenuList = ({ children }) => (
   <Context.Consumer>
      {context => (
         <div className={context.offline ? styles.wrapperOffline : styles.wrapper}>
            {children}
         </div>
      )}
   </Context.Consumer>
)

export default MenuList