import { chunk, random, sampleSize } from 'lodash'

const MONTHS = [
   'Styczeń',
   'Luty',
   'Marzec',
   'Kwiecień',
   'Maj',
   'Czerwiec',
   'Lipiec',
   'Sierpień',
   'Wrzesień',
   'Październik',
   'Listopad',
   'Grudzień',
]

export class Debounce {
   constructor() {
      this.debounceTimeout = null
   }

   run(fn, wait) {
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(fn, wait)
   }
}

export function convertFarmsAddresses(data) {
   return data.map((farm) => {
      const { street, housenumber, postalcode, city } = farm.address
      farm.addressText = `${street} ${housenumber}, ${postalcode} ${city}`
      return farm
   })
}

export function setFilterProvince(province) {
   const provinces = []
   if (province) {
      provinces.push(province)
   }

   this.setState(
      (prevState) => {
         return {
            ...prevState,
            filter: {
               services: prevState.filter.services,
               provinces: provinces,
            },
            showFilters: false,
         }
      },
      () => {
         setSearchData.call(
            this,
            this.state.searchPhrase ? this.state.searchPhrase : ''
         )
         this.setState({
            showFilters: true,
         })
      }
   )
}

export function setOffline() {
   if (!this.state.offline) {
      this.setState({
         offline: true,
      })
   }
}

export function getEventCategoryTitle(which, category) {
   const response = which === 'future' ? 'Nadchodzące w: ' : 'Archiwalne w: '
   const categorySplit = category.split('-')
   const monthName = MONTHS[parseInt(categorySplit[0]) - 1]
   return `${response}${monthName} ${categorySplit[1]}`
}

export function filterAds(data) {
   const responseData = data.entries.filter((x) => x.status === true)
   return responseData[random(0, responseData.length - 1)]
}

export function getFilteredEvents(which, category) {
   const filterPhrase = `/kalendarz-imprez/${which}/${category}/1`
   let searchedCategory = null

   if (which === 'future') {
      searchedCategory = this.state.eventsDates.future.find(
         (x) => x.link === filterPhrase
      )
   } else {
      searchedCategory = this.state.eventsDates.past.find(
         (x) => x.link === filterPhrase
      )
   }

   if (searchedCategory) {
      const response = []

      searchedCategory.events.forEach((event) => {
         const searchedEvent = this.state.eventsData.entries.find(
            (x) => x._id === event
         )
         response.push(searchedEvent)
      })

      return response
   } else {
      return []
   }
}

const debounce = new Debounce()

export function setSearchPhrase(e) {
   let phrase = null
   if (typeof e === 'string') {
      phrase = e
   } else {
      e.persist()
      phrase = e.target.value
   }

   debounce.run(() => {
      this.setState({
         searchPhrase: phrase,
      })
      setSearchData.call(this, phrase)
   }, 500)
}

export function convertDate(data) {
   data = data.map((element) => {
      const dateSplit = element.date.split('-')
      const date = {
         year: parseInt(dateSplit[0]),
         month: parseInt(dateSplit[1]),
         day: parseInt(dateSplit[2]),
      }
      date.monthName = MONTHS[date.month - 1]
      date.format = `${date.day} ${date.monthName} ${date.year}`
      date.timestamp = Date.UTC(date.year, date.month - 1, date.day)

      element.date = date
      return element
   })

   return data
}

export function getEventsDates(data) {
   const eventsDates = {
      future: [],
      past: [],
   }

   data = data.map((element) => {
      const category = `${element.date.monthName} ${element.date.year}`
      const which = Date.now() < element.date.timestamp ? 'future' : 'past'
      const link = `/kalendarz-imprez/${which}/${element.date.month}-${element.date.year}/1`
      const index = eventsDates[which].findIndex((x) => x.display === category)

      if (index === -1) {
         eventsDates[which].push({
            display: category,
            link: link,
            events: [element._id],
         })
      } else {
         eventsDates[which][index].events.push(element._id)
      }

      return true
   })

   eventsDates.future.sort((a, b) => {
      if (a.link > b.link) return 1
      if (a.link < b.link) return -1
      return 0
   })
   eventsDates.past.sort((a, b) => {
      if (a.link > b.link) return -1
      if (a.link < b.link) return 1
      return 0
   })

   return eventsDates
}

export function setSort(e) {
   const name = e.target.getAttribute('name')
   const value = e.target.getAttribute('value')
   this.setState(
      (prevState) => {
         return {
            ...prevState,
            sort: {
               howto: name === 'howto' ? value : prevState.sort.howto,
               name: name === 'name' ? value : prevState.sort.name,
            },
         }
      },
      () => {
         setSearchData.call(this, this.state.searchPhrase)
      }
   )
}

export function getService(id) {
   return this.state.servicesData.entries.find((x) => x._id === id)
}

export function setFilter(e) {
   const name = e.target.getAttribute('filter')
   const value = e.target.getAttribute('name')
   const forceRerender = e.target.getAttribute('rerender')
   const filter = this.state.filter
   const indexOf = filter[name].indexOf(value)

   if (indexOf === -1) {
      filter[name].push(value)
   } else {
      filter[name].splice(indexOf, 1)
   }

   this.setState(
      {
         filter: filter,
      },
      () => {
         setSearchData.call(this, this.state.searchPhrase)

         if (forceRerender) {
            this.setState(
               {
                  showFilters: false,
               },
               () => {
                  this.setState({
                     showFilters: true,
                  })
               }
            )
         }
      }
   )
}

export function pagination(array, page, itemCount, callback) {
   page = parseInt(page)
   const paginationArray = chunk(array, itemCount)
   const pages = []
   paginationArray.forEach((el, i) => {
      pages.push(i + 1)
   })

   if (page > 0 && page <= paginationArray.length) {
      return callback({
         data: paginationArray[page - 1],
         prev: page > 1 ? page - 1 : false,
         next: page < paginationArray.length ? page + 1 : false,
         pages: pages,
      })
   } else {
      return callback({
         data: [],
         prev: false,
         next: false,
         pages: pages,
      })
   }
}

export function getFarm(id, callback) {
   const data = this.state.farmsData.entries.find((x) => x._id === id)
   if (data) {
      document.title = data.name
   }
   return callback(data)
}

export function getEvent(id, callback) {
   const data = this.state.eventsData.entries.find((x) => x._id === id)
   if (data) {
      document.title = data.title
   }
   return callback(data)
}

export function getPage(slug, callback) {
   const data = this.state.pagesData.entries.find((x) => x.title_slug === slug)
   if (data) {
      document.title = data.title
   }
   return callback(data)
}

export function openCloseMenu() {
   if (this.state.isMenuOpen) {
      this.setState({
         isMenuOpen: false,
         isSidebarOpen: false,
      })
   } else {
      this.setState({
         isMenuOpen: true,
         isSidebarOpen: false,
      })
   }
}

export function openCloseSidebar() {
   if (this.state.isSidebarOpen) {
      this.setState({
         isSidebarOpen: false,
         isMenuOpen: false,
      })
   } else {
      this.setState({
         isSidebarOpen: true,
         isMenuOpen: false,
      })
   }
}

export function closeAllMenus() {
   this.setState({
      isSidebarOpen: false,
      isMenuOpen: false,
   })
}

export function setSearchData(phrase) {
   if (
      phrase === '' &&
      this.state.filter.services.length === 0 &&
      this.state.filter.provinces.length === 0
   ) {
      this.setState({
         searchData: null,
         discoverFarms: sampleSize(this.state.farmsData.entries, 10),
      })
   } else {
      phrase = phrase.toLowerCase()
      const data = {}

      if (phrase !== '') {
         data.farms = this.state.farmsData.entries.filter((element) => {
            if (
               element.name.toLowerCase().includes(phrase) ||
               element.representative.toLowerCase().includes(phrase) ||
               element.description.toLowerCase().includes(phrase) ||
               element.addressText.toLowerCase().includes(phrase)
            ) {
               return true
            } else {
               return false
            }
         })
      } else {
         data.farms = this.state.farmsData.entries
      }

      data.farms = filterFarmsData.call(this, data.farms)
      data.farms = sortFarmsData.call(this, data.farms)

      this.setState({
         searchData: data,
      })
   }
}

export function sortFarmsData(data) {
   if (data) {
      const howto = {
         greater: this.state.sort.howto === 'asc' ? -1 : 1,
         less: this.state.sort.howto === 'asc' ? 1 : -1,
      }

      data = data.sort((a, b) => {
         switch (this.state.sort.name) {
            case 'az': {
               if (a.name > b.name) return howto.greater
               if (a.name < b.name) return howto.less
               break
            }
            case 'placename': {
               if (a.addressText > b.addressText) return howto.greater
               if (a.addressText < b.addressText) return howto.less
               break
            }
            case 'servicescount': {
               if (a.services.length > b.services.length) return howto.greater
               if (a.services.length < b.services.length) return howto.less
               break
            }
            default: {
               console.log('0')
               return 0
            }
         }
         console.log('0')
         return 0
      })
      return data
   } else {
      return []
   }
}

export function filterFarmsData(data) {
   if (data) {
      data = data.filter((element) => {
         let check = true
         this.state.filter.services.forEach((service) => {
            if (element.services.findIndex((x) => x._id === service) === -1) {
               check = false
            }
         })
         if (this.state.filter.provinces.length !== 0 && check) {
            let check2 = false
            this.state.filter.provinces.forEach((province) => {
               if (element.address.province === province) {
                  check2 = true
               }
            })
            check = check2
         }

         return check
      })

      return data
   } else {
      return []
   }
}
