import React from 'react'
import styles from './FooterLink.module.scss'
import { Link } from 'react-router-dom'

const FooterLink = ({ children, ...props }) => (
   <li className={styles.wrapper}>
      <Link to={props.href} className={styles.link}>
         {children}
      </Link>
   </li>
)

export default FooterLink