import React, { useEffect } from 'react'
import styles from './HomeView.module.scss'
import SearchBar from '../../components/SearchBar/SearchBar'
import HomeBox from '../../components/HomeBox/HomeBox'
import BoxButton from '../../components/BoxButton/BoxButton'
import Context from '../../Context'
import { URL } from '../../Config'
import ReactHtmlParser from 'react-html-parser'
import Loader from '../../components/Loader/Loader'
import IconPublic from '@material-ui/icons/Public'
import HeaderPhrase from '../../components/HeaderPhrase/HeaderPhrase'

const HomeView = () => {
   useEffect(() => {
      document.title = "Wypoczynek na wsi"
   })

   return (
      <Context.Consumer>
         {context => (
            <>
               <HeaderPhrase/>
               <div className={styles.container}>
                  <SearchBar/>
                  <div className={styles.grid}>
                     {context.randomFarms ? context.randomFarms.map(box => (
                        <HomeBox
                           key={box._id}
                           title={box.name}
                           place={box.addressText}
                           src={URL + box.photos[0].path}
                           href={`oferta-agroturystyczna/${box._id}`}
                        >
                           { ReactHtmlParser(box.description) }
                        </HomeBox>
                     )) : (
                        <Loader />
                     )}

                  </div>
                  <BoxButton
                     icon={IconPublic}
                     title="Zobacz na mapie"
                     href="/oferta-agroturystyczna"
                     theme="light"
                  />
               </div>
            </>
         )}
      </Context.Consumer>
   )
}

export default HomeView