import React from 'react'
import styles from './Radio.module.scss'
import Context from '../../../Context'

const Radio = ({children, ...props}) => (
   <Context.Consumer>
      {context => (
         <div className={styles.wrapper}>
            <input
               type="radio"
               id={props.id}
               name={props.name}
               value={props.value}
               className={styles.radio}
               defaultChecked={props.checked}
               onChange={context.setSort}
            />
            <div className={styles.checkmark}></div>
            <label htmlFor={props.id} className={styles.label}>
               {children}
            </label>
         </div>
      )}
   </Context.Consumer>
)

export default Radio