import React from 'react'
import styles from './SidebarIcon.module.scss'
import Context from '../../../Context'
import {
   Search as IconSearch,
   ArrowBack as IconArrowBack,
   MoreVert as IconMoreVert
} from '@material-ui/icons'

const SidebarIcon = ({...props}) => (
   <Context.Consumer>
      {context => (
         <div
            className={styles.sidebarIcon}
            onClick={context.openCloseSidebar}
         >
            {props.icon === 'search' && (
               <IconSearch className={!context.isSidebarOpen ? styles.iconActive : styles.icon}/>
            )}
            {props.icon === 'more' && (
               <IconMoreVert className={!context.isSidebarOpen ? styles.iconActive : styles.icon}/>
            )}
            <IconArrowBack className={context.isSidebarOpen ? styles.iconActive : styles.icon}/>
         </div>
      )}
   </Context.Consumer>
)

export default SidebarIcon