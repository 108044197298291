import {
   Call as IconCall,

   HowToReg as IconRepresent, Language as IconWeb, MailOutline as IconMail, Room as IconPlace
} from '@material-ui/icons'
import React from 'react'
import styles from './FarmInfo.module.scss'

const FarmInfo = ({ ...props }) => {
   const phone = props.phone || null
   let numbersArray = null
   const numbersNewArray = []

   if (phone) {
      numbersArray = phone.split(',')
      numbersArray.forEach(element => {
         if (element !== ' ' || element !== '') {
            numbersNewArray.push({
               text: element.trim(),
               value: element.replace(/-| |\./g, '')
            })
         }
      })

      if (numbersNewArray.length > 0) {
         props.phone = numbersNewArray
      }
   }

   return (
      <div className={styles.wrapper}>
         <div className={styles.iconWrapper}>
            {props.icon === 'place' && (
               <IconPlace className={styles.icon} />
            )}
            {props.icon === 'represent' && (
               <IconRepresent className={styles.icon} />
            )}
            {props.icon === 'mail' && (
               <IconMail className={styles.icon} />
            )}
            {props.icon === 'call' && (
               <IconCall className={styles.icon} />
            )}
            {props.icon === 'web' && (
               <IconWeb className={styles.icon} />
            )}
         </div>
         <div className={styles.content}>
            <span className={styles.label}>
               {props.label}:
            </span>

            {props.text && (
               <div className={styles.text}>
                  {props.text}
               </div>
            )}
            {props.email && (
               <a className={styles.link} href={'mailto:' + props.email}>
                  {props.email}
               </a>
            )}
            {props.phone && props.phone.map((element, i) => {
               return (
                  <a key={'phonenumber' + i} className={styles.link} href={'tel:' + element.value}>
                     {element.text}
                  </a>
               )
            })}
            {props.href && (
               <a className={styles.link} href={props.href} rel="noopener noreferrer" target="_blank">
                  {props.href.replace(/http:\/\/|https:\/\/|www./, '')}
               </a>
            )}
            {props.facebook && (
               <a className={styles.link} href={props.facebook} rel="noopener noreferrer" target="_blank">
                  Link do fanpage
               </a>
            )}

         </div>
      </div>
   )
}

export default FarmInfo