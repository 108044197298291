import React from 'react'
import IconPlace from '@material-ui/icons/Place'
import styles from './HomeBox.module.scss'
import { Link } from 'react-router-dom'
import Img from '../Img/Img'

const HomeBox = ({children, ...props}) => (
   <Link to={props.href} className={styles.wrapper}>
      <Img className={styles.background} size={[300, 300]} alt={props.title} src={props.src} />

      <div className={styles.content}>
         <div className={styles.title}>
            {props.title}
         </div>
         <div className={styles.subheader}>
            <IconPlace className={styles.icon}/>
            <span className={styles.place}>
               {props.place}
            </span>
         </div>
         <div className={styles.description}>
            {children}
         </div>
      </div>

      <div className={styles.shadow}></div>
   </Link>
)

export default HomeBox