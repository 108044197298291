import React from 'react'
import 'react-image-lightbox/style.css'
import styles from './Gallery.module.scss'
import Image from './Image/Image'
import Lightbox from 'react-image-lightbox'
import { URL } from '../../Config'

class Gallery extends React.Component {
   state = {
      photoIndex: 0,
      isOpen: false,
      images: []
   }

   openGallery(index) {
      this.setState({
         photoIndex: index,
         isOpen: true
      })
   }

   componentDidUpdate() {
      if(this.props.images !== this.state.images) {
         this.setState({
            images: []
         }, () => {
            this.setState({
               images: this.props.images
            })
         })
      }
   }

   render() {
      const { photoIndex, isOpen } = this.state

      return (
         <>
            <div className={styles.wrapper}>
               {this.state.images.length > 0 && this.state.images.map((photo, i) => {
                  return (
                     <Image
                        key={'photo'+i}
                        src={URL + photo.path}
                        title={photo.meta.title}
                        onClick={this.openGallery.bind(this, i)}
                     />
                  )
               })}
            </div>
            {isOpen && (
               <Lightbox
                  mainSrc={URL + this.props.images[photoIndex].path}
                  nextSrc={URL + this.props.images[(photoIndex + 1) % this.props.images.length].path}
                  prevSrc={URL + this.props.images[(photoIndex + this.props.images.length - 1) % this.props.images.length].path}
                  onCloseRequest={() => this.setState({ isOpen: false })}
                  onMovePrevRequest={() =>
                     this.setState({
                        photoIndex: (photoIndex + this.props.images.length - 1) % this.props.images.length,
                     })
                  }
                  onMoveNextRequest={() =>
                  this.setState({
                     photoIndex: (photoIndex + 1) % this.props.images.length,
                  })
                  }
               />
            )}
         </>
      )
   }
}

export default Gallery