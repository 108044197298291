import ls from "localstorage-ttl";
import { API_token, URL } from "./Config";

function getCollection(name, callback, errorCallback) {
  const response = ls.get(name);
  if (response) {
    callback(response);
  }

  fetch(`${URL}/api/collections/get/${name}?token=${API_token}`)
    .then((res) => res.json())
    .then((res) => {
      ls.set(name, res, 31536000);
      return callback(res);
    })
    .catch((error) => errorCallback(error));
}

function getImageThumbUrl(src, width, height) {
  return `${URL}/api/cockpit/image?token=${API_token}&src=${src}&w=${width}&h=${height}`;
}

function getImageThumb(url, callback, errorCallback) {
  fetch(url)
    .then((res) => res.text())
    .then((res) => callback(res))
    .catch((error) => errorCallback(error));
}

const API = {
  getCollection: getCollection,
  getImageThumbUrl: getImageThumbUrl,
  getImageThumb: getImageThumb,
};

export default API;
