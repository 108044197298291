import IconCheckCircle from '@material-ui/icons/CheckCircle'
import React from 'react'
import { URL } from '../../Config'
import Context from '../../Context'
import styles from './ServicesLineList.module.scss'


class ServicesLineList extends React.Component {
   state = {
      isError: false
   }

   handleError() {
      if (!this.state.isError) {
         this.setState({
            isError: true
         })
      }
   }

   render() {
      return (
         <Context.Consumer>
            {context => (
               <div className={styles.wrapper}>
                  {!this.state.isError ? (
                     <>
                        {context.servicesData && this.props.services.map(element => {
                           const service = context.getService(element._id)
                           return (
                              <img
                                 key={`service-icon-${service._id}`}
                                 src={URL + service.icon.path}
                                 alt={service.name}
                                 title={service.name}
                                 className={styles.icon}
                                 onError={this.handleError.bind(this)}
                              />
                           )
                        })}
                     </>
                  ) : (
                        <>
                           <IconCheckCircle className={styles.replacementIcon} />
                           <span className={styles.replacementText}>{this.props.services.length}</span>
                        </>
                     )}

               </div>
            )}
         </Context.Consumer>
      )
   }
}

export default ServicesLineList