import React, { Suspense } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import Banner from '../../components/Banner/Banner'
import EventsSidebar from '../../components/EventsSidebar/EventsSidebar'
import Footer from '../../components/Footer/Footer'
import Loader from '../../components/Loader/Loader'
import MobileBar from '../../components/MobileBar/MobileBar'
import Navigation from '../../components/Navigation/Navigation'
import OfflineNotice from '../../components/OfflineNotice/OfflineNotice'
import Reset from '../../components/Reset/Reset'
import SearchPanel from '../../components/SearchPanel/SearchPanel'
import ToTop from '../../components/ToTop/ToTop'
import Context from '../../Context'
import '../../css/global.css'
import EventsCalendarView from '../EventsCalendarView/EventsCalendarView'
import EventView from '../EventView/EventView'
import FarmsIndexView from '../FarmsIndexView/FarmsIndexView'
import FarmView from '../FarmView/FarmView'
import HomeView from '../HomeView/HomeView'
import InfoPageView from '../InfoPageView/InfoPageView'
import View404 from '../View404/View404'
import styles from './Root.module.scss'

const MapView = React.lazy(() => import('../MapView/MapView'))

const Root = () => {
   return (
      <BrowserRouter>
         <Context.Consumer>
            {(context) => (
               <>
                  <Reset closeAllMenus={context.closeAllMenus} />
                  <OfflineNotice />
                  <MobileBar />
                  <ToTop />
                  <div className={styles.wrapper}>
                     <div
                        className={
                           context.isMenuOpen
                              ? context.offline
                                 ? styles.sidebarContainerLeftActiveOffline
                                 : styles.sidebarContainerLeftActive
                              : context.offline
                              ? styles.sidebarContainerLeft
                              : styles.sidebarContainerLeft
                        }
                     >
                        <Navigation />
                     </div>

                     <div className={styles.container}>
                        <Switch>
                           <Route
                              path='/oferta-agroturystyczna/indeks-gospodarstw'
                              component={FarmsIndexView}
                           />
                           <Route
                              path='/oferta-agroturystyczna/:id'
                              component={FarmView}
                           />
                           <Route path='/oferta-agroturystyczna'>
                              <Suspense fallback={<Loader />}>
                                 <MapView />
                              </Suspense>
                           </Route>

                           <Route
                              path='/kalendarz-imprez/:which/:category/:page/:id'
                              component={EventView}
                           />
                           <Route
                              path='/kalendarz-imprez/:which/:category/:page'
                              component={EventsCalendarView}
                           />
                           <Route
                              path='/kalendarz-imprez/:page/:id'
                              component={EventView}
                           />
                           <Route
                              path='/kalendarz-imprez/:page'
                              component={EventsCalendarView}
                           />
                           <Route path='/kalendarz-imprez/'>
                              <Redirect to='/kalendarz-imprez/1' />
                           </Route>

                           <Route path='/:slug' component={InfoPageView} />

                           <Route exact path='/' component={HomeView} />

                           <Route component={View404} />
                        </Switch>
                     </div>

                     <Route path='/oferta-agroturystyczna'>
                        <div
                           className={
                              context.isSidebarOpen
                                 ? context.offline
                                    ? styles.sidebarContainerRightActiveOffline
                                    : styles.sidebarContainerRightActive
                                 : context.offline
                                 ? styles.sidebarContainerRightOffline
                                 : styles.sidebarContainerRight
                           }
                        >
                           <SearchPanel />
                        </div>
                     </Route>

                     <Route path='/kalendarz-imprez'>
                        <div
                           className={
                              context.isSidebarOpen
                                 ? styles.sidebarContainerRightActive
                                 : styles.sidebarContainerRight
                           }
                        >
                           <EventsSidebar />
                        </div>
                     </Route>
                  </div>

                  <Switch>
                     <Route exact path='/'>
                        <Banner home />
                     </Route>
                     <Route path='*' component={Banner} />
                  </Switch>

                  <Footer />
               </>
            )}
         </Context.Consumer>
      </BrowserRouter>
   )
}

export default Root
